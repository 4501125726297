export const About  =  () => {
    return (
        <div>
            <h1>About</h1>
            <p>
                This is my hobby to teach and share knowledge in NLP and ML. I have created content, blogs, videos along with the code.
                <br/>
                For few videos i have  created  Hindi and Telugu versions as well.
                <br/>
                For Contact details click on the bottom of the page.
            </p>
        </div>
    )
}
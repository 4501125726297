import {Card, Elevation} from "@blueprintjs/core";

export const FullStack = () => {
    return(
        <Card className='card'  elevation={Elevation.ONE} interactive={false}>
            <ul>
                <h2>Full Stack</h2>
                <li><h3>Docker for end to end (React + Mongo + Python)</h3></li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/uYpBZV37L2U?list=PL01e4bnNRw-TLoPGoUR5fo2ETuj6qomNj"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/docker-for-end-to-end-react-mongo-python-e249bcfa6bc4'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/reactpythonmongodocker/tree/master'>github</a>
                </p>
                <li>
                    <h3>Redux Fundamentals of React in TypeScript</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/r7WSM2l7FNM?list=PL01e4bnNRw-TLoPGoUR5fo2ETuj6qomNj"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/redux-fundamentals-of-react-in-typescript-c98642a35b6b'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/reduxExample/tree/master'>github</a>
                </p>
            </ul>
        </Card>
    )
}
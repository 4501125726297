import React, {useState} from 'react';
import './App.scss';
import {
    Alignment,
    Button,
    Card,
    Classes,
    Divider,
    Drawer,
    DrawerSize, Elevation, Icon,
    Menu, MenuItem,
    Navbar,
    Position,
    Switch as SwitchComponent
} from "@blueprintjs/core";
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {Home} from "./home/Home";
import {About} from "./about/About";
import {Contact} from "./contact/Contact";
import {Privacy} from "./privacy/Privacy";

function App() {
    const [darkTheme, setDarkTheme] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

  return (
    <div className={darkTheme ? 'bp3-dark' : ''}>
        <BrowserRouter>
        <Drawer
            icon="info-sign"
            isOpen={isOpen}
            onClose={handleClose}
            title="Meeraj Kanaparthi"
            position={Position.LEFT}
            size={DrawerSize.SMALL}
        >
            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        <strong>
                            Please click on  the option for the task:
                        </strong>
                    </p>
                    <Menu className={Classes.ELEVATION_1}>
                            <MenuItem  className="bp4-minimal" icon="sim-card" text="Languages">
                                <MenuItem  className="bp4-minimal" icon="badge" text="English" />
                                <MenuItem  className="bp4-minimal" icon="clean" text="Hindi" />
                                <MenuItem  className="bp4-minimal" icon="bookmark" text="Telugu" />
                            </MenuItem>
                            <MenuItem  className="bp4-minimal" icon="snowflake" text="Tags" >
                                <MenuItem  className="bp4-minimal" icon="archive" text="Shared" />
                            </MenuItem>
                        <MenuItem  className="bp4-minimal" icon="user" text="User">
                            <MenuItem  className="bp4-minimal" icon="user" text="User" />
                        </MenuItem>
                    </Menu>

                </div>
            </div>
            <div className={Classes.DRAWER_FOOTER}>Footer</div>
        </Drawer>
      <header className="App-header">
          <Navbar>
              <Navbar.Group align={Alignment.LEFT}>
                  <Button className="bp4-minimal" icon="menu" text="" onClick={handleOpen} />
                  <Navbar.Divider />
                  <Navbar.Heading>Meeraj Kanaparthi</Navbar.Heading>
                  <Navbar.Divider />
                  <Link  to="/">
                      <Button className="bp4-minimal" icon="home" text="Home" />
                  </Link>
                  <Button className="bp4-minimal" icon="application" text="Application" />
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                  <Link  to="/about">
                      <Icon icon={"info-sign"} size={20}/>
                  </Link>
                  <Button className="bp4-minimal" icon="inbox" text="Login" disabled={true}/>
                  <Button className="bp4-minimal" icon="record" text="Register" disabled={true}/>
                  <Navbar.Divider/>
                  <SwitchComponent onChange={() => setDarkTheme(!darkTheme)} labelElement={<strong>Dark Theme</strong>} />
              </Navbar.Group>
          </Navbar>
      </header>
        <main className="main">
            <Card className='card'  elevation={Elevation.FOUR} interactive={true}>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path ="/about">
                    <About/>
                </Route>
                <Route path ="/contact">
                    <Contact/>
                </Route>
                <Route path ="/privacy">
                    <Privacy/>
                </Route>
            </Switch>
            </Card>
        </main>
        <Divider />
        <footer className="footer">
            <div>
                Copyright © 2021,
                Meeraj Kanaparthi, Hyderabad, India.
                All rights reserved
            </div>
            <div>
                <Link to="/privacy">
                <Button>Privacy policy</Button>
                </Link> |
                <Link to="/contact">
                <Button>Contact Us</Button>
                </Link>
            </div>
        </footer>
        </BrowserRouter>
    </div>
  );
}

export default App;

import {Card, Elevation} from "@blueprintjs/core";

export const AdvanceML = () => {
    return(
        <Card className='card'  elevation={Elevation.ONE} interactive={false}>
            <ul>
                <h2>Advance Machine Learning</h2>
                <li><h3>06 Principal Component Analysis — PCA</h3></li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/Zyo9lTvhAxk?list=PL01e4bnNRw-SZvEaIxSOo44Ip4ZPzLYvh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/aaarXiwDnyk?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">06 Principal Component Analysis — PCA (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/oXFQ8fBKRkg?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">06 Principal Component Analysis — PCA (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>09 - Eigenface</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/120k1UVuZHI?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/09-eigenface-47e3e0799aef'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/lsWokby_Pk0?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">09 - Eigenface (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/SDhX8yeLprE?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">09 - Eigenface (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>10 - Perceptron </h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/X2ZLk8v42VE?list=PL01e4bnNRw-SZvEaIxSOo44Ip4ZPzLYvh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/10-perceptron-algorithm-demo-77fef075f395'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/blob/develop/algorithms/Perceptron%20Gradient%20Descent.ipynb'>github</a>
                    <br/>
                    <a href="https://youtu.be/YSrpImyzFQ8?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">10 - Perceptron (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/SDhX8yeLprE?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">10 - Perceptron (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>11 - Mean Square Error Gradient Descent</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/N_9Ki-gyuQ4?list=PL01e4bnNRw-SZvEaIxSOo44Ip4ZPzLYvh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/11-mean-square-error-gradient-descent-ae9bdb33548f'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/m7bouq6hpcM?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">11 - Mean Square Error Gradient Descent (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/VriwslWiAX0?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">11 - Mean Square Error Gradient Descent (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>14 - Logistic Regression</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/9pP2yK4t__Q?list=PL01e4bnNRw-SZvEaIxSOo44Ip4ZPzLYvh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/14-logistic-regression-8df305b5a28e'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/T02N5DDRxIQ?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">14 - Logistic Regression (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/5_aHGm7f69c?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">14 - Logistic Regression (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>15 - K Nearest Neighbour</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/Z5HH89JYcR0?list=PL01e4bnNRw-SZvEaIxSOo44Ip4ZPzLYvh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/k-nearest-neighbour-431e0996a268'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/h73ROkVP-WU?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">15 - K Nearest Neighbour (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/P4v4yGA76BQ?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">15 - K Nearest Neighbour (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>16 - Maximum Likelihood Estimation</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/Uh9351UKFMY?list=PL01e4bnNRw-SZvEaIxSOo44Ip4ZPzLYvh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://medium.com/swlh/16-mle-maximum-likelihood-estimation-354a0612c0ea'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/N9dbaT3khr0?list=PL01e4bnNRw-Rx9paxRUOheI2X8tIf7nJ5">16 - Maximum Likelihood Estimation (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/8p1ZifadpbY?list=PL01e4bnNRw-RNqeHoBLtH9OChDwMy3t3X">16 - Maximum Likelihood Estimation (Telugu)- తెలుగు</a>
                </p>
            </ul>
        </Card>
    )
}
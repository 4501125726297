import './Home.scss';
import {Card, Elevation, Tab, Tabs} from "@blueprintjs/core";
import {FullStack} from "./FullStack";
import {Basics} from "./Basics";
import {AdvanceML} from "./AdvanceML";
import logo from './Meeraj.jpeg';
export const Home  =  () => {
    return (
        <div>
            <h1>Welcome, this is Meeraj Kanaparthi</h1>
            <div className='headingContent'>
            <img src="https://miro.medium.com/fit/c/262/262/1*Rr6FjqrY5bsuuhHv-QzcBg.jpeg" />
            <p>
                This is my hobby to teach and share knowledge in NLP and ML. I have created content, blogs, videos along with the code.
                <br/>
                For few videos i have  created  Hindi and Telugu versions as well.
                <br/>
                For Contact details click on the bottom of the page.
            </p>
            </div>
            <Card className='card'  elevation={Elevation.ONE} interactive={false}>

                <iframe width="420" height="315" src="https://www.youtube.com/embed/iJls49fNg38" frameBorder="0"
                        allowFullScreen></iframe>
            </Card>
            <Tabs id="TabsExample" key="verticle">
                <Tab id="nd" title="FullStack" panel={<FullStack/>} />
                <Tab id="ab" title="Advance" panel={<AdvanceML/>} />
                <Tab id="mb" title="Basics" panel={<Basics/>} />
            </Tabs>
        </div>
    )
}
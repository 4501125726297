export const Contact = () => {
    return(
        <div>
            <ul>
                <li>Contact:</li>
                Meeraj Kanaparthi <br/>
                Gachibowli, Hyderabad, Telangana, India 500032
            </ul>

            <ul>
                <li>email:</li>
                meeraj.kanaparthi@gmail.com
            </ul>
            <ul>
                <li>phone:</li>
                +91 9885863330
            </ul>
            <ul>
                <li>whatsapp:</li>
                +91 9885863330
            </ul>
            <ul>
                <li><a href='https://www.linkedin.com/in/meeraj-k-69ba76189/'> Linked In </a></li>
            </ul>
            <ul>
                <li><a href='https://www.youtube.com/channel/UCtcEuGgCTWzzXBGvwCKm5VA'> YouTube</a></li>
            </ul>
            <ul>
                <li><a href='https://www.facebook.com/meeraj.k.35'> Facebook </a></li>

            </ul>
            <ul>
                <li><a href="https://kmeeraj.medium.com/"> Medium</a></li>
            </ul>
            <ul>
                <li><a href='https://www.instagram.com/meeraj.kanaparthi1/'> Instagram </a></li>
            </ul>
        </div>
    )
}
import {Card, Elevation} from "@blueprintjs/core";

export const Basics = () => {
    return(
        <Card className='card'  elevation={Elevation.ONE} interactive={false}>
            <ul>
                <h2>Basics of Machine Learning</h2>
                <li><h3>01 Mean</h3></li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/TAHr-dLsDy8?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/01-mean-9f2e2eb1d19a'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://www.youtube.com/watch?v=TGvSkbYb8oA&list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">01 Mean (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://www.youtube.com/watch?v=9CMgNDcmfgk&list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">01 Mean (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>02 - Covariance Matrix</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/120k1UVuZHI?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/02-covariance-matrix-d088a5796ff2'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/BweHu3TeLdM?list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">02 - Covariance Matrix (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/h4sMW9sgwsw?list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">02 - Covariance Matrix (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>03 Eigen Values and Vectors </h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/flZI65Ylttg?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/03-eigen-values-and-eigen-vectors-d5255a5d5483'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/ckI-o-XT1bk?list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">03 Eigen Values and Vectors (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/N4TbUh5uSzQ?list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">03 Eigen Values and Vectors (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>04 Singular Value Decomposition</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/TCExTpJnpNY?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/03-eigen-values-and-eigen-vectors-d5255a5d5483'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/qJ8Vqe4PX70?list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">04 Singular Value Decomposition (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/ckQUSHv6HVs?list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">04 Singular Value Decomposition (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>07 - Loss Function</h3>
                </li>
                <iframe width="420" height="351"
                        src="https://www.youtube.com/embed/120k1UVuZHI?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/02-covariance-matrix-d088a5796ff2'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/e-1FbCtYKQY?list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">07 - Loss Function (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/dZ7uMwy4Kyc?list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">07 - Loss Function (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>08 - Gradient Descent</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/phTWgFd1ZvU?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/02-covariance-matrix-d088a5796ff2'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/V9U2QBgBJBo?list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">08 - Gradient Descent (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/aI24UiKpQ1g?list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">08 - Gradient Descent (Telugu)- తెలుగు</a>
                </p>
                <li>
                    <h3>12 - Simple Naive Bayes</h3>
                </li>
                <iframe width="420" height="315"
                        src="https://www.youtube.com/embed/t79W78TiBJ4?list=PL01e4bnNRw-S71tN3OJGEMbvb9blR733G"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    <br/>
                    Check out Medium Link Here : <a href='https://kmeeraj.medium.com/simple-naive-bayes-c50415438bed'>Medium Link</a>
                    <br/>
                    <a href='https://github.com/kmeeraj/machinelearning/tree/develop'>github</a>
                    <br/>
                    <a href="https://youtu.be/BweHu3TeLdM?list=PL01e4bnNRw-TyOmg_LHziwfa0HVfqOF9J">12 - Simple Naive Bayes (Hindi) - हिंदी</a>
                    <br/>
                    <a href="https://youtu.be/h4sMW9sgwsw?list=PL01e4bnNRw-RFxA2sst7KLZL1kN_36n-J">12 - Simple Naive Bayes (Telugu)- తెలుగు</a>
                </p>
            </ul>
        </Card>
    )
}